export default function QualificationToString(pQualification: string){
    switch (pQualification) {
        case "ssd":
            return "Schulsanitäter";
        case "esan":
            return "Einsatzsanitäter";
        case "eh_ssd":
            return "Ersthelfer im SSD";
        case "rh":
            return "Rettungshelfer";
        case "rh_nrw":
            return "Rettungshelfer NRW";
        case "rs":
            return "Rettungssanitäter";
        case "nfs":
            return "Notfallsanitäter";
        case "eh":
            return "Ersthelfer";
        case "none":
            return "Keine";
        default:
            break;
    }
}