import React, { useEffect, useState } from "react";
import {
  Alarm,
  Key,
  LocationOn,
  UnpublishedRounded,
  Warning,
} from "@mui/icons-material";
import {
  Box,
  Container,
  FormControlLabel,
  FormLabel,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Autocomplete,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
  Divider,
  ListItemButton,
  ListSubheader,
  Button,
  Tooltip,
} from "@mui/material";
import { Field, Form, Formik, useFormik } from "formik";
import SanAlarmNavbar from "../../components/navbar";
import userService from "../../services/user.service";
import QualificationToString from "../../misc/QualifictaionToString";
import SanAlarmNavbarDrawer from "../../components/navbarDrawer";
import keywordSuggestions from "../../misc/KeywordSuggestions";
import locationSuggestions from "../../misc/LocationSuggestions";

class User {
  firstname: string;
  lastname: string;
  qualification: string;
  email: string;
  username: string;
  pushToken: string;

  constructor(
    pFirstname: string,
    pLastname: string,
    pQualification: string,
    pEmail: string,
    pPushToken: string,
    pUsername: string
  ) {
    this.firstname = pFirstname;
    this.lastname = pLastname;
    this.qualification = pQualification;
    this.email = pEmail;
    this.username = pUsername;
    this.pushToken = pPushToken;
  }
}

export default function SendAlarm() {
  const [users, setUsers] = useState<User[]>([]);
  const [activeUsers, setActiveUsers] = useState<User[]>([]);
  const [passiveUsers, setPassiveUsers] = useState<User[]>([]);
  const [submitting, setSubmitting] = useState(false);

  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);

  useEffect(() => {
    userService.getUsersWithShifts().then((res) => {
      setUsers(res.data.other as User[]);
      setPassiveUsers(res.data.passiveUsers as User[]);
      setActiveUsers(res.data.activeUsers as User[]);
    });
  }, []);

  const toggleUser = (pUser: User) => {
    var updatedList = [...selectedUsers];
    var currentIndex = selectedUsers.indexOf(pUser);

    if (currentIndex === -1) {
      updatedList.push(pUser);
    } else {
      updatedList.splice(currentIndex, 1);
    }

    setSelectedUsers(updatedList);
  };
  return (
    <SanAlarmNavbarDrawer>
      <Container component="main" maxWidth="md">
        <h1>Alarmieren</h1>
        <Formik
          initialValues={{
            keyword: "",
            location: "",
          }}
          onSubmit={(values) => {
            let pushTokens: any = [];
            selectedUsers.forEach((user) => {
              pushTokens = [...pushTokens, user.pushToken];
            });

            userService.createCall(
              values.location,
              values.keyword,
              values.keyword,
              pushTokens
            );
          }}
        >
          {({ errors, touched, setFieldValue }) => (
            <Form>
              <FormLabel component="legend" sx={{ mb: 2 }}>
                Allgemeine Informationen
              </FormLabel>
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <Autocomplete
                    autoComplete
                    freeSolo
                    id="keyword"
                    options={keywordSuggestions}
                    onChange={(e, value) => {
                      setFieldValue("keyword", value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Stichwort"
                        name="keyword"
                        onChange={(e) => {
                          setFieldValue("keyword", e.target.value);
                        }}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <>
                              <InputAdornment position="start">
                                <LocationOn />
                              </InputAdornment>
                              {params.InputProps.startAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    autoComplete
                    freeSolo
                    id="location"
                    options={locationSuggestions}
                    onChange={(e, value) => {
                      setFieldValue("location", value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Standort"
                        name="location"
                        onChange={(e) => {
                          setFieldValue("location", e.target.value);
                        }}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <>
                              <InputAdornment position="start">
                                <LocationOn />
                              </InputAdornment>
                              {params.InputProps.startAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                {
                  //<Grid item xs={6}>
                  //<FormLabel component="legend">
                  //  Zu alarmierende Personen
                  //</FormLabel>
                  //<RadioGroup
                  //  aria-label="gender"
                  //  defaultValue="female"
                  //  name="radio-buttons-group"
                  //>
                  //  <FormControlLabel
                  //    value="active"
                  //    control={<Radio />}
                  //    label="Innendienst"
                  //  />
                  //  <FormControlLabel
                  //    value="passive"
                  //    control={<Radio />}
                  //    label="Außendienst"
                  //  />
                  //  <FormControlLabel
                  //    value="today"
                  //    control={<Radio />}
                  //    label="Innen- & Außendienst"
                  //  />
                  //  <FormControlLabel
                  //    value="all"
                  //    control={<Radio />}
                  //    label="Alle"
                  //  />
                  //</RadioGroup>
                  //</Grid>
                }
                <Grid item xs={6}>
                  <List
                    sx={{
                      width: "100%",
                      maxWidth: 360,
                      bgcolor: "background.paper",
                      position: "relative",
                      overflow: "auto",
                      maxHeight: 500,
                      "& ul": { padding: 0 },
                    }}
                    subheader={<li />}
                  >
                    <ListSubheader>Innendienst</ListSubheader>
                    {activeUsers.map((user) =>
                      UserListItem(user, toggleUser, selectedUsers)
                    )}
                    <ListSubheader>Außendienst</ListSubheader>
                    {passiveUsers.map((user) =>
                      UserListItem(user, toggleUser, selectedUsers)
                    )}
                    <ListSubheader>Alle</ListSubheader>
                    {users.map((user) =>
                      UserListItem(user, toggleUser, selectedUsers)
                    )}
                  </List>
                </Grid>
              </Grid>
              <Button
                disabled={submitting}
                variant="contained"
                startIcon={<Alarm />}
                type="submit"
              >
                Alarmieren
              </Button>
            </Form>
          )}
        </Formik>
      </Container>
    </SanAlarmNavbarDrawer>
  );
}

function UserListItem(
  user: User,
  toggleUser: (user: User) => void,
  selectedUsers: User[]
) {
  return (
    <>
      <ListItem
        key={user.username}
        secondaryAction={
          user.pushToken ? null : (
            <Tooltip title="Dieser Nutzer kann wahrscheinlich keine Alarmierungen empfangen. Fordern Sie ihn auf sich erneut in der App einzuloggen.">
              <Warning color="warning" />
            </Tooltip>
          )
        }
        disablePadding
      >
        <ListItemButton dense onClick={() => toggleUser(user)}>
          <ListItemIcon>
            <Checkbox
              edge="start"
              checked={selectedUsers.indexOf(user) !== -1}
              disableRipple
            />
          </ListItemIcon>
          <ListItemText
            secondary={
              <ListItemText
                primary={QualificationToString(user.qualification)}
              />
            }
            primary={user.lastname + ", " + user.firstname}
          />
        </ListItemButton>
      </ListItem>

      <Divider variant="inset" component="li" />
    </>
  );
}
