import {
  Button,
  Container,
  Grid,
  Card,
  Typography,
  CardContent,
  CardActionArea,
  CardActions,
} from "@mui/material";
import React, { useState } from "react";
import { NotificationsActive, List } from "@mui/icons-material";
import authService from "../services/auth.service";
import { useNavigate } from "react-router";
import SanAlarmNavbarDrawer from "../components/navbarDrawer";

export default function Dashboard() {
  const [user] = useState(authService.getCurrentUser());

  const navigate = useNavigate();

  return (
    <SanAlarmNavbarDrawer>
      <Container component="main" maxWidth="md">
        <h1>{user.orga.name}</h1>
        <h2>Hallo, {user.firstname + " " + user.lastname + "!"}</h2>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Card>
              <CardContent>
                <Typography
                  sx={{ fontSize: 14 }}
                  color="text.secondary"
                  gutterBottom
                >
                  Einsätze
                </Typography>
                <Typography component="div" variant="h5">
                  Keine aktiven Einsätze
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  size="small"
                  startIcon={<List />}
                  onClick={() => navigate("/dashboard/calls")}
                >
                  Alle Einsätze
                </Button>
                <Button
                  size="small"
                  variant="outlined"
                  startIcon={<NotificationsActive />}
                  onClick={() => navigate("/dashboard/sendAlarm")}
                >
                  Alarmieren
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card>
              <CardContent>
                <Typography
                  sx={{ fontSize: 14 }}
                  color="text.secondary"
                  gutterBottom
                >
                  Lager
                </Typography>
                <Typography component="div" variant="h5">
                  3 Artikel müssen nachbestellt werden
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  size="small"
                  startIcon={<List />}
                  onClick={() => navigate("/dashboard/items")}
                >
                  Alle Artikel
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </SanAlarmNavbarDrawer>
  );
}
