const keywordSuggestions = [
    "Nasenbluten", 
    "Nasenbluten über 15min", 
    "Unklare Lage", 
    "Bewusstlose Person", 
    "Hyperventilation", 
    "Chemieunfall", 
    "Psych. Ausnahmezustand", 
    "Treppensturz", 
    "Fenstersturz EG/1OG/2OG",
    "Sturz",
    "Unfall mit Roller", 
    "Unfall mit Fahrrad", 
    "Unfall mit Auto", 
    "Schnittverletzung", 
    "Kopfplatzwunde", 
    "Sportunfall",
    "Kreislaufprobleme", 
    "Panikattacke ", 
    "Atemnot", 
    "Asthmaanfall", 
    "Herzinfarkt",
    "Schlaganfall", 
    "Vergiftung", 
    "Suizidversuch", 
    "Krampfanfall", 
    "Schlägerei", 
    "Verbrennung", 
    "Verätzung",
    "Kreislaufstillstand",
    "Bruch (V.a.)",
    "Leitersturz/Sturz aus großer Höhe",
    "Quetschung",
    "Prellung"
  ];

export default keywordSuggestions;
export {};