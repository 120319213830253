import { Add, Delete, Edit } from "@mui/icons-material";
import {
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Dialog,
  TextField,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
  IconButton,
} from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import SanAlarmNavbarDrawer from "../../components/navbarDrawer";
import userService from "../../services/user.service";
import * as yup from "yup";
import CreateItemDialog from "./items/createItemDialog";

interface Item {
  name: string;
  orga: string;
  actualQuantity: number;
  setQuantity: number;
  articleNr: string;
  location: string;
}

export default function Items() {
  const [items, setItems] = useState<Item[]>([]);
  const [dialog, setDialog] = useState(false);

  useEffect(() => {
    userService.getItems().then((res) => {
      setItems(res.data as Item[]);
    });
  }, []);

  const openDialog = () => {
    setDialog(true);
  };

  const closeDialog = () => {
    setDialog(false);
  };

  return (
    <>
      <SanAlarmNavbarDrawer>
        <Container component="main" maxWidth="md">
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <h1>Lager</h1>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                startIcon={<Add />}
                onClick={() => openDialog()}
              >
                ANLEGEN
              </Button>
            </Grid>
          </Grid>
          <TableContainer component={Paper}>
            <Table sx={{}} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>A.-Nr.</TableCell>
                  <TableCell>Artikel</TableCell>
                  <TableCell align="right">Bestand</TableCell>
                  <TableCell align="right">Soll-Bestand</TableCell>
                  <TableCell align="right">Lagerort</TableCell>
                  <TableCell align="right">Aktionen</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>{row.articleNr}</TableCell>
                    <TableCell component="th" scope="row">
                      <b>{row.name}</b>
                    </TableCell>
                    <TableCell align="right">{row.actualQuantity}</TableCell>
                    <TableCell align="right">{row.setQuantity}</TableCell>
                    <TableCell align="right">{row.location}</TableCell>
                    <TableCell align="right">
                      <IconButton>
                        <Edit />
                      </IconButton>
                      <IconButton>
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Container>
      </SanAlarmNavbarDrawer>
      <CreateItemDialog open={dialog} closeDialog={closeDialog} />
    </>
  );
}
