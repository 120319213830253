import axios from 'axios';

const API_URL = 'https://api.synfonex.de/sanalarm/dev/';

class AuthService {
  async login(username: String, password: String) {
    try {
      const res = await axios.post(`${API_URL}user/login`, {
        username, password,
      });
      if (res.data.token) {
        console.log(res.data.token);
        localStorage.setItem('user', JSON.stringify(res.data));
        return res.data;
      }
      return '';
    } catch (e) {
      return '';
    }
  }

  logout = () => {
    localStorage.removeItem('user');
    window.location.href = '/login';
  };

  getCurrentUser() {
    if (localStorage.getItem('user') != null) {
      return JSON.parse(localStorage.getItem('user') as string);
    }
    return null;
  }

  isLoggedIn() {
    if (localStorage.getItem('user') != null) {
      return true;
    }
    return false;
  }
}

export default new AuthService();
