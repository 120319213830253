import { Grid, Container, Button, TextField } from "@mui/material";
import SanAlarmNavbarDrawer from "../../../components/navbarDrawer";
import { Add } from "@mui/icons-material";
import { Form, Formik, useFormik } from "formik";
import { Box } from "@mui/system";
import * as yup from "yup";
import "yup-phone";

function CreateUser() {
  const NewUserSchema = yup.object().shape({
    firstname: yup.string().required("Dieses Feld darf nicht leer sein."),
    lastname: yup.string().required("Dieses Feld darf nicht leer sein."),
    username: yup.string().required("Dieses Feld darf nicht leer sein."),
    phone: yup
      .string()
      .required("Dieses Feld darf nicht leer sein.")
      .phone("DE", true, "Bitte geben Sie eine gültige Telefonnummer ein."),
    email: yup.string().email("Bitte geben Sie eine gültige E-Mail ein."),
    password: yup
      .string()
      .min(8, "Das Passwort muss mindestens 8 Zeichen lang sein.")
      .required("Dieses Feld darf nicht leer sein."),
    confirmPassword: yup
      .string()
      .oneOf(
        [yup.ref("password"), null],
        "Die Passwörter müssen übereinstimmen."
      ),
  });

  const formik = useFormik({
    initialValues: {
      username: "",
      firstname: "",
      lastname: "",
      password: "",
      confirmPassword: "",
      email: "",
      phone: "",
    },
    onSubmit: (v) => {
      console.log(v);
    },
    validationSchema: NewUserSchema,
  });

  return (
    <SanAlarmNavbarDrawer>
      <Container component="main" maxWidth="md">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <h1>Neuer Nutzer</h1>
          </Grid>
        </Grid>
        <Box component="form" onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="firstname"
                label="Vorname"
                value={formik.values.firstname}
                onChange={formik.handleChange}
                error={
                  formik.touched.firstname && Boolean(formik.errors.firstname)
                }
                helperText={formik.touched.firstname && formik.errors.firstname}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="lastname"
                label="Nachname"
                value={formik.values.lastname}
                onChange={formik.handleChange}
                error={
                  formik.touched.lastname && Boolean(formik.errors.lastname)
                }
                helperText={formik.touched.lastname && formik.errors.lastname}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                id="username"
                label="Nutzername"
                value={formik.values.username}
                onChange={formik.handleChange}
                error={
                  formik.touched.username && Boolean(formik.errors.username)
                }
                helperText={formik.touched.username && formik.errors.username}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                id="email"
                label="E-Mail"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                id="phone"
                label="Mobil"
                value={formik.values.phone}
                onChange={formik.handleChange}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="password"
                label="Passwort"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="confirmPassword"
                label="Passwort bestätigen"
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                error={
                  formik.touched.confirmPassword &&
                  Boolean(formik.errors.confirmPassword)
                }
                helperText={
                  formik.touched.confirmPassword &&
                  formik.errors.confirmPassword
                }
              />
            </Grid>
          </Grid>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Absenden
          </Button>
        </Box>
      </Container>
    </SanAlarmNavbarDrawer>
  );
}

export default CreateUser;
