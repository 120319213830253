import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Alert } from '@mui/material';
import SanAlarmNavbar from '../components/navbar';
import authService from '../services/auth.service';

const Copyright = function (props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'© '}
      Synfonex
      {' '}
      {new Date().getFullYear()}
    </Typography>
  );
};

// const required = (value: String) => {
//    if (!value) {
//        return (
//            <Typography variant="overline" color="red">Diese Feld muss ausgefüllt sein</Typography>
//        );
//    }
// }

export default function SignIn() {

    const navigate = useNavigate();
  const [remember, setRemember] = useState(true);
  const [error, setError] = useState(false);
  const [user] = useState(authService.getCurrentUser());

  if (user != null) {
    navigate('/dashboard');
  }

  const SigninSchema = yup.object().shape({
    username: yup
      .string()
      .min(2, 'Der Nutzername ist zu kurz')
      .required('Bitte gebe einen Nutzernamen ein.'),
    password: yup
      .string()
      .min(2, 'Das Passwort ist zu kurz')
      .required('Bitte gebe ein Passwort ein.'),
  });

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: SigninSchema,
    onSubmit: (values) => {
      authService.login(values.username.trim(), values.password.trim()).then((res) => {
        setError(false);
        if (res !== '') {
          navigate('/dashboard');
        } else { setError(true); }
      });
    },
    validateOnChange: true,
    validateOnBlur: true,
  });

  return (

    <>
      <SanAlarmNavbar />
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >

          {error
                        && (
                        <Alert severity="error" sx={{ width: '100%', m: 2 }} onClose={() => setError(false)}>
                          Zugangsdaten falsch
                        </Alert>
                        )}

          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <AccountCircle />
          </Avatar>
          <Typography component="h1" variant="h5">
            Einloggen
          </Typography>

          <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Nutzername"
              name="username"
              autoComplete="username"
              autoFocus
              value={formik.values.username}
              onChange={formik.handleChange}
              error={formik.touched.username && Boolean(formik.errors.username)}
              helperText={formik.touched.username && formik.errors.username}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Passwort"
              type="password"
              id="password"
              autoComplete="current-password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
            <FormControlLabel
              control={<Checkbox onChange={(e) => setRemember(e.target.checked)} checked={remember} color="primary" />}
              label="Eingeloggt bleiben?"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Einloggen
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="/forgot-password" variant="body2">
                  Passwort vergessen?
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </>
  );
}
