import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import { useState } from "react";
import * as yup from "yup";
import userService from "../../../services/user.service";

interface CreateArticleDialogProps {
  open: boolean;
  closeDialog: () => void;
}

export default function CreateItemDialog(props: CreateArticleDialogProps) {
  const { open, closeDialog } = props;

  const [error, setError] = useState("");

  const ArticleSchema = yup.object().shape({
    name: yup
      .string()
      .min(3, "Der Artikelname ist zu kurz")
      .required("Bitte gebe einen Artikelnamen ein."),
    actualQuantity: yup.number().required("Bitte gebe einen Ist-Bestand ein."),
    setQuantity: yup.number().required("Bitte gebe einen Soll-Bestand ein."),
    location: yup
      .string()
      .min(3, "Bitte gebe einen längeren Wert ein.")
      .required("Bitte gebe einen Lagerort ein"),
    articleNr: yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      actualQuantity: 0,
      setQuantity: 0,
      location: "",
      articleNr: "",
    },
    validationSchema: ArticleSchema,
    onSubmit: (values) => {
      userService
        .createItem(
          values.name,
          values.setQuantity,
          values.actualQuantity,
          values.location,
          values.articleNr
        )
        .then((res) => {
          if (res.status !== 201) {
            setError("Ungültiger Rückgabewert");
          } else {
            closeDialog();
          }
        });
    },
    validateOnChange: true,
    validateOnBlur: true,
  });

  return (
    <Dialog open={open} onClose={closeDialog} fullWidth>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>Artikel anlegen</DialogTitle>
        <DialogContent>
          {error !== "" && (
            <Alert
              severity="error"
              sx={{ width: "100%", m: 2 }}
              onClose={() => setError("")}
            >
              {error}
            </Alert>
          )}
          <Grid container spacing={2} sx={{ p: 2 }}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="name"
                name="name"
                label="Artikelname"
                autoFocus
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                type="number"
                fullWidth
                name="actualQuantity"
                id="actualQuantity"
                label="Ist-Bestand"
                autoFocus
                value={formik.values.actualQuantity}
                onChange={formik.handleChange}
                error={
                  formik.touched.actualQuantity &&
                  Boolean(formik.errors.actualQuantity)
                }
                helperText={
                  formik.touched.actualQuantity && formik.errors.actualQuantity
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                type="number"
                fullWidth
                id="setQuantity"
                name="setQuantity"
                label="Soll-Bestand"
                autoFocus
                value={formik.values.setQuantity}
                onChange={formik.handleChange}
                error={
                  formik.touched.setQuantity &&
                  Boolean(formik.errors.setQuantity)
                }
                helperText={
                  formik.touched.setQuantity && formik.errors.setQuantity
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                fullWidth
                name="location"
                id="location"
                label="Lagerort"
                autoFocus
                value={formik.values.location}
                onChange={formik.handleChange}
                error={
                  formik.touched.location && Boolean(formik.errors.location)
                }
                helperText={formik.touched.location && formik.errors.location}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="articleNr"
                name="articleNr"
                label="Artikel-Nr. (Optional)"
                autoFocus
                value={formik.values.articleNr}
                onChange={formik.handleChange}
                error={
                  formik.touched.articleNr && Boolean(formik.errors.articleNr)
                }
                helperText={formik.touched.articleNr && formik.errors.articleNr}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => closeDialog()}>Abbrechen</Button>
          <Button variant="contained" color="success" type="submit">
            Absenden
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
