import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Login from "./routes/login";
import ForgotPassword from "./routes/forgot-password";
import Dashboard from "./routes/dashboard";
import RequireAuth from "./services/requireAuth";
import SendAlarm from "./routes/dashboard/sendAlarm";
import Items from "./routes/dashboard/items";
import Calls from "./routes/dashboard/calls";
import Users from "./routes/dashboard/user/users";
import CreateUser from "./routes/dashboard/user/createUser";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route
            path="/dashboard"
            element={
              <RequireAuth>
                <Dashboard />
              </RequireAuth>
            }
          />
          <Route
            path="/dashboard/sendAlarm"
            element={
              <RequireAuth>
                <SendAlarm />
              </RequireAuth>
            }
          />
          <Route
            path="/dashboard/items"
            element={
              <RequireAuth>
                <Items />
              </RequireAuth>
            }
          />
          <Route
            path="/dashboard/calls"
            element={
              <RequireAuth>
                <Calls />
              </RequireAuth>
            }
          />
          <Route
            path="/dashboard/user"
            element={
              <RequireAuth>
                <Users />
              </RequireAuth>
            }
          />
          <Route
            path="/dashboard/createUser"
            element={
              <RequireAuth>
                <CreateUser />
              </RequireAuth>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
