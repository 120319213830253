import { Add, ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Typography,
  List,
  ListItem,
  Button,
  Grid,
  ListItemText,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import SanAlarmNavbarDrawer from "../../components/navbarDrawer";
import userService from "../../services/user.service";

interface Call {
  _id: string;
  begin: string;
  orga: Orga;
  issuer: User;
  short: string;
  desc: string;
  state: string;
  location: string;
  end: string;
  log: { [index: string]: string };
}

interface Orga {
  name: string;
  location: string;
}

interface User {
  firstname: string;
  lastname: string;
  qualification: string;
  email: string;
  username: string;
  push_token: string;
}

export default function Calls() {
  const [calls, setCalls] = useState<Call[]>([]);
  const [time, setTime] = useState(new Date().getTime());

  const navigate = useNavigate();

  useEffect(() => {
    userService.getCalls().then((res) => {
      setCalls(res.data as Call[]);
      setTime(new Date().getTime());
    });
    const interval = setInterval(() => {
      userService.getCalls().then((res) => {
        setCalls(res.data as Call[]);
        setTime(new Date().getTime());
      });
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <SanAlarmNavbarDrawer>
      <Container component="main" maxWidth="md">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <h1>Einsätze</h1>
          </Grid>

          <Grid item>
            <Button
              variant="contained"
              startIcon={<Add />}
              onClick={() => navigate("/dashboard/sendAlarm")}
            >
              Alarmieren
            </Button>
          </Grid>
        </Grid>

        {calls.map((row) => (
          <Accordion key={row._id}>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography>
                <b>{row.desc}</b>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List>
                <ListItemText>
                  <Typography>
                    <i>
                      Erstellt von: {row.issuer.firstname} {row.issuer.lastname}
                      {" am "}
                      {new Date(parseInt(row.begin)).toLocaleString("de-de")}
                    </i>
                  </Typography>
                </ListItemText>
                <ListItemText>{"Status: " + row.state}</ListItemText>
                {Object.keys(row.log).map((entry) => (
                  <ListItemText>
                    <p>
                      {new Date(parseInt(entry)).toLocaleString("de-de")}{" "}
                      <b>{row.log[entry]}</b>
                    </p>
                  </ListItemText>
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
        ))}
        <Typography variant="overline" sx={{ textAlign: "center" }}>
          Zuletzt aktualisiert: {new Date(time).toLocaleTimeString("de-de")}
        </Typography>
      </Container>
    </SanAlarmNavbarDrawer>
  );
}
