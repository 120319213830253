import { Add, Delete, Edit } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import SanAlarmNavbar from "../../../components/navbar";
import SanAlarmNavbarDrawer from "../../../components/navbarDrawer";
import QualificationToString from "../../../misc/QualifictaionToString";
import RoleToString from "../../../misc/RoleToString";
import User from "../../../models/User";
import userService from "../../../services/user.service";

function Users() {
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      const userRes = await userService.getUsers();

      if (userRes && userRes.status === 200) {
        userRes.data.forEach((user: User) => {
          setUsers((prevState) => [...prevState, user]);
        });
        setLoading(false);
      }
    }

    fetchData();
  }, []);

  return (
    <SanAlarmNavbarDrawer>
      <Container component="main" maxWidth="md">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <h1>Nutzer</h1>
          </Grid>

          <Grid item>
            <Button
              variant="contained"
              startIcon={<Add />}
              onClick={() => navigate("/dashboard/createUser")}
            >
              Nutzer anlegen
            </Button>
          </Grid>
        </Grid>

        <Grid container>
          {loading ? (
            <CircularProgress />
          ) : (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Name</TableCell>
                    <TableCell align="left">Qualifikation</TableCell>
                    <TableCell align="left">Rolle</TableCell>
                    <TableCell align="right">Aktionen</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users.map((user) => (
                    <TableRow>
                      <TableCell align="left">
                        <Tooltip title={user.email} placement="left">
                          <Typography fontWeight="bold">
                            {user.firstname + " " + user.lastname}
                          </Typography>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="left">
                        {QualificationToString(user.qualification)}
                      </TableCell>
                      <TableCell align="left">
                        {RoleToString(user.role)}
                      </TableCell>
                      <TableCell align="right">
                        <IconButton size="small">
                          <Edit />
                        </IconButton>
                        <IconButton size="small">
                          <Delete color="warning" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Grid>
      </Container>
    </SanAlarmNavbarDrawer>
  );
}

export default Users;
