import axios from 'axios';
import authHeader from './auth-header';

const API_URL = 'https://api.synfonex.de/sanalarm/dev';

class UserService {
  getItems() {
    return axios.get(`${API_URL}/item`, { headers: authHeader() })
  };

  getCalls() {
    return axios.get(`${API_URL}/call/getAll`, { headers: authHeader() })
  }

  createItem(name: string, setQuantity: number, actualQuantity: number, location: string, articleNr: string){
    return axios.post(API_URL + '/item', {
      'name': name,
      'actualQuantity': actualQuantity,
      'setQuantity': setQuantity,
      'articleNr': articleNr,
      'location': location
    }, { headers: authHeader()})
  }

  getUsers(){
    return axios.get(`${API_URL}/user/`, { headers: authHeader() })
  }
registerUser(username: string, firstname: string, lastname: string, email: string, phone: string){
    return axios.post(`${API_URL}/user/`, { headers: authHeader() })
  }


    getUsersWithShifts(){
    return axios.get(`${API_URL}/user/getAll`, { headers: authHeader() })
  }

  createCall(pLocation: String, pDesc: string, pShort: string, pFcmIds: [String]){
    return axios.post(`${API_URL}/call`, {
      'desc': pDesc,
      'location': pLocation,
      'short': pShort,
      'fcmIds': pFcmIds,
    }, { headers: authHeader()})
  }
}

export default new UserService();
