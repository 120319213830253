import React from "react";
import { ThemeProvider } from "@emotion/react";
import { createTheme, CssBaseline, useMediaQuery } from "@mui/material";
import { Outlet } from "react-router-dom";

export default function App() {
  // const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const theme = React.useMemo(
    () =>
      createTheme({
        typography: {
          fontFamily: "Lato",
          overline: {
            textAlign: "center",
          },
        },
        palette: {
          mode: "light",
          // mode: prefersDarkMode ? 'dark' : 'light',
        },
        shape: {
          borderRadius: 8,
        },
      }),
    []
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Outlet />
    </ThemeProvider>
  );
}
